<template>
  <div>
       <section class="home-slider position-relative">
                    <div id="carouselExampleInterval" class="carousel slide carousel-fade" data-bs-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active" data-bs-interval="3000">
                                <div class="bg-home slider-rtl-2 d-flex align-items-center" style="background:url('../../images/banner/international1.jpg') center center;background-size: cover">
                                    <div class="container">
                                        <div class="row align-items-center mt-5">
                                            <div class="col-lg-7 col-md-7">
                                                <div class="title-heading mt-4">
                                                    <h1 class="  title-white fw-bold mb-3 myh1" style="color:#fffef4">{{ $t('banner.international') }}</h1>
                                                    </div>
                                            </div><!--end col-->
                                        </div><!--end row-->
                                    </div><!--end container-->
                                </div>
                            </div>

                            <div class="carousel-item" data-bs-interval="3000">
                                <div class="bg-home slider-rtl-1 d-flex align-items-center" style="background:url('../../images/banner/mny2.jpg') center center;background-size: cover">
                                    <div class="container">
                                        <div class="row align-items-center mt-5">
                                            <div class="col-lg-7 col-md-7">
                                                <div class="title-heading mt-4">
                                                    <h1 class="myh1  title-white fw-bold mb-3" style="color:#fffef4">{{ $t('banner.mny') }}</h1>
                                                    </div>
                                            </div><!--end col-->
                                        </div><!--end row-->
                                    </div><!--end container-->
                                </div>
                            </div>

                            <div class="carousel-item" data-bs-interval="3000">
                                <div class="bg-home slider-rtl-1 d-flex align-items-center" style="background:url('../../images/banner/sport.jpg') center center;background-size: cover">
                                    <div class="container">
                                        <div class="row align-items-center mt-5">
                                            <div class="col-lg-7 col-md-7">
                                                <div class="title-heading mt-4">
                                                    <h1 class="myh1  title-white fw-bold mb-3" style="color:#fffef4">{{ $t('banner.sport') }}</h1>
                                                    </div>
                                            </div><!--end col-->
                                        </div><!--end row-->
                                    </div><!--end container-->
                                </div>
                            </div>

                            <div class="carousel-item" data-bs-interval="3000">
                                <div class="bg-home slider-rtl-1 d-flex align-items-center" style="background:url('../../images/banner/innovation2.jpg') center center;background-size: cover">
                                    <div class="container">
                                        <div class="row align-items-center mt-5">
                                            <div class="col-lg-7 col-md-7">
                                                <div class="title-heading mt-4">
                                                    <h1 class="myh1  title-white fw-bold mb-3" style="color:#fffef4">{{ $t('banner.innovation') }}</h1>
                                                     </div>
                                            </div><!--end col-->
                                        </div><!--end row-->
                                    </div><!--end container-->
                                </div>
                            </div>

                            <div class="carousel-item" data-bs-interval="3000">
                                <div class="bg-home slider-rtl-1 d-flex align-items-center" style="background:url('../../images/banner/shipping3.jpg') center center;background-size: cover">
                                    <div class="container">
                                        <div class="row align-items-center mt-5">
                                            <div class="col-lg-7 col-md-7">
                                                <div class="title-heading mt-4">
                                                    <h1 class="myh1  title-white fw-bold mb-3" style="color:#fffef4">{{ $t('banner.shipping') }}</h1>
                                                    </div>
                                            </div><!--end col-->
                                        </div><!--end row-->
                                    </div><!--end container-->
                                </div>
                            </div>

                            <div class="carousel-item" data-bs-interval="3000">
                                <div class="bg-home slider-rtl-1 d-flex align-items-center" style="background:url('../../images/banner/colis.jpg') center center;background-size: cover">
                                    <div class="container">
                                        <div class="row align-items-center mt-5">
                                            <div class="col-lg-7 col-md-7">
                                                <div class="title-heading mt-4">
                                                    <h1 class="myh1  title-white fw-bold mb-3" style="color:#fffef4">{{ $t('banner.colis') }}</h1>
                                                    </div>
                                            </div><!--end col-->
                                        </div><!--end row-->
                                    </div><!--end container-->
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="3000">
                                <div class="bg-home slider-rtl-1 d-flex align-items-center" style="background:url('../../images/appartement/17.jpg') center center;background-size: cover">
                                    <div class="container">
                                        <div class="row align-items-center mt-5">
                                            <div class="col-lg-7 col-md-7">
                                                <div class="title-heading mt-4">
                                                    <h1 class="myh1  title-white fw-bold mb-3" style="color:#fffef4">{{ $t('appartement.card1.titre') }}</h1>
                                                    </div>
                                            </div><!--end col-->
                                        </div><!--end row-->
                                    </div><!--end container-->
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="3000">
                                <div class="bg-home slider-rtl-1 d-flex align-items-center" style="background:url('../../images/appartement/1.jpg') center center;background-size: cover">
                                    <div class="container">
                                        <div class="row align-items-center mt-5">
                                            <div class="col-lg-7 col-md-7">
                                                <div class="title-heading mt-4">
                                                    <h1 class="myh1  title-white fw-bold mb-3" style="color:#fffef4">{{ $t('appartement.card1.titre') }}</h1>
                                                    </div>
                                            </div><!--end col-->
                                        </div><!--end row-->
                                    </div><!--end container-->
                                </div>
                            </div>

                        </div>
                        <a class="carousel-control-prev bg-dark" href="#carouselExampleInterval" role="button" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">{{ $t('prev') }}</span>
                        </a>
                        <a class="carousel-control-next bg-dark" href="#carouselExampleInterval" role="button" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">{{ $t('next') }}</span>
                        </a>
                    </div>
                </section><!--end section-->
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import de from '/public/js/app.js'
  import da from '/public/js/feather.min.js'
  import dd from '/public/js/bootstrap.bundle.min.js'
  import dc from '/public/js/plugins.init.js'
  import i18n from '../i18n'
  export default {
    name: 'Banner',

    data: () => ({

    }),
    computed: {
     ...mapGetters(['lng']),   
    },
    methods: {
        changedtofr: function(){
            i18n.locale = 'fr'
            this.$store.commit('setlng',{lang: this.$t('lang1')})

            let language = this.$route.params.lang;
            if(language === 'en'){
                let splitted = this.$route.path.split(`${language}`);
                let url = '/fr'+splitted[1]
                this.$router.push(url)
            }
        },
        changedtoen: function(){
            i18n.locale = 'en'
            this.$store.commit('setlng',{lang: this.$t('lang2')})

            let language = this.$route.params.lang;
            if(language != 'en'){
                let splitted = this.$route.path.split(`${language}`);
                let url = '/en'+splitted[1]
                this.$router.push(url)
            }
        },
        toggleMenu: function(){
            document.getElementById('isToggle').classList.toggle('open');
            var isOpen = document.getElementById('navigation')
            if (isOpen.style.display === "block") {
                isOpen.style.display = "none";
            } else {
                isOpen.style.display = "block";
            }
        }
    },
  }
</script>

<style scoped>
@media (min-width: 501px) {
    .myh1 {
    font-size: 6rem !important;
font-weight: 300;
line-height: 6rem;
letter-spacing: -0.015625em !important;
font-family: "Roboto", sans-serif !important;
}
}


@media (max-width: 500px) {
    .myh1 {
        font-size: 2rem !important;
    }
}
</style>