import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import International from '../views/International.vue'
import Sport from '../views/Sport.vue'
import Innovation from '../views/Innovation.vue'
import Services from '../views/Services.vue'
import S2aAppartement from '../views/S2aAppartement.vue'
import i18n from '../i18n'

import store from "../store";
Vue.use(VueRouter)
 
const routes = [
  {
    path: '/',
    redirect: `/${i18n.locale}`
  },
  {
    path: '/:lang',
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: '/',
        component: Home,
      },
      {
        path: 'international',
        component: International,
      },
      {
        path: 'sport',
        component: Sport,
      },
      {
        path: 'innovation',
        component: Innovation,
      },
      {
        path: 'services',
        component: Services,
      },
      {
        path: 'appartements',
        component: S2aAppartement,
      }
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 500)
    })
  }
  
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next({ name: 'Login'});
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next({ name: 'PersonalDetails'});
      return;
    }
    next();
  } else {
    next();
  }
});



export default router
