<template>
  <v-app>
    <div class="" id="">
          <!-- Navbar STart -->
              <Head></Head>
          <!-- Navbar End -->
  
          <!-- Hero Start -->
              <Banner></Banner>
          <!-- Hero End -->
  
          <!-- Section Start -->
         
          <!--end section-->
          <!-- section End -->
  
                  <!-- Start -->
               
                  <!--end section-->
                  <!-- End -->
  
                  <div class="container mt-100" style="margin-bottom: 90px;">
                      <div class="row align-items-center">
                          <div class="col-lg-5 col-md-6">
                              <img src="../../public/mobile011.png" class="img-fluid" alt="">
                          </div><!--end col-->
  
                          <div class="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                              <div class="section-title ms-lg-5">
                                  <h4 class="title mb-4">{{ $t('innovation.head4') }}</h4>
                                  <p class="text-muted">{{ $t('innovation.subhead4') }}
                                  </p>
                                  <p class="text-muted">{{ $t('innovation.subhead5') }}
                                  </p>
                              </div>
                          </div><!--end col-->
                      </div><!--end row-->
                  </div>
                  <div class="container mt-100" style="margin-bottom: 90px;">
                      <div class="row align-items-center">
                          <div class="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                              <div class="section-title ms-lg-5">
                                  <h4 class="title mb-4" align="right">{{ $t('innovation.head5') }}</h4>
                                  <p class="text-muted" align="right">{{ $t('innovation.subhead6') }}
                                  </p>
                              </div>
                          </div>
                          <div class="col-lg-5 col-md-6">
                              <img src="../../public/inoov2.jpg" class="img-fluid" alt="">
                          </div><!--end col-->
  
                          <!--end col-->
                      </div><!--end row-->
                  </div>
                  <div class="container mt-100" style="margin-bottom: 90px;">
                      <div class="row align-items-center">
                          <div class="col-lg-5 col-md-6">
                              <img src="../../public/inoo3.jpg" class="img-fluid" alt="">
                          </div><!--end col-->
                          <div class="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                              <div class="section-title ms-lg-5">
                                  <h4 class="title mb-4" align="left">{{ $t('innovation.head6') }}</h4>
                                  <p class="text-muted" align="left">{{ $t('innovation.subhead7') }}
                                  </p>
                              </div>
                          </div>
  
                          <!--end col-->
                      </div><!--end row-->
                  </div>
                  <!--end container-->
  
          <!-- Footer Start -->
              <Foot></Foot>
          <!-- Footer End -->
    </div>
  </v-app>
  </template>
  
  <script>
    import {mapGetters} from 'vuex'
    import de from '/public/js/app.js'
    import da from '/public/js/feather.min.js'
    import dd from '/public/js/bootstrap.bundle.min.js'
    import dc from '/public/js/plugins.init.js'
    import i18n from '../i18n'
    import Foot from '../components/Foot.vue'
    import Head from '../components/Head.vue'
    import Banner from '../components/Banner.vue'
    export default {
      name: 'Innovation',
      components: {
          Foot,
          Head,
          Banner
      },
      data (){
              return {
                  model: null,
                  model1: null,
                  tabImages:[
                      {url:'/images/sport/slider-1.jpg',text:'Paris'},
                      {url:'/images/sport/slider-1.jpg',text:'Paris'},
                      {url:'/images/sport/slider-2.jpg',text:'Paris'},
                      {url:'/images/sport/slider-1.jpg',text:'Paris'}
                  ],
                  customers:[
                      {name:" Pour mes transactions à l'étranger, je me fie à S2A. C'est rapide, simple et efficace. ",text:'- Denkey Aser'},
                      {name:" Les services offerts par S2A International ont été à la hauteur de mes espérances surtout que leurs couts sont abordables. ",text:'- Tossou Daniel'},
                      {name:" J'ai entendu parlé de S2A par le biais d'un ami. Et depuis je ne fais confiance qu'à eux. ",text:'Dossou Franjuste'},
                      {name:" Le dévelopement d'application est un vaste monde. Pour mieux cerner la réalisation de mon projet, j'ai pu compter sur S2A. ",text:'- Onewin Femi'},
                      {name:" Ce que je préfère avec S2A c'est leur fiabilité et la qualité de leur service client.",text:'- Taboukouna Florent'},
                      {name:" Désormais je ne fais confiance qu'à S2A pour m'équiper en articles de sport de qualité. ",text:'- Gbeze Martin'}
                  ],
              }
          },
      computed: {
          ...mapGetters(['lng']),
      },
      methods: {
          changedtofr: function(){
              i18n.locale = 'fr'
              this.$store.commit('setlng',{lang: this.$t('lang1')})
  
              let language = this.$route.params.lang;
              if(language === 'en'){
                  let splitted = this.$route.path.split(`${language}`);
                  let url = '/fr'+splitted[1]
                  this.$router.push(url)
              }
          },
          changedtoen: function(){
              i18n.locale = 'en'
              this.$store.commit('setlng',{lang: this.$t('lang2')})
  
              let language = this.$route.params.lang;
              if(language != 'en'){
                  let splitted = this.$route.path.split(`${language}`);
                  let url = '/en'+splitted[1]
                  this.$router.push(url)
              }
          },
          toggleMenu: function(){
              document.getElementById('isToggle').classList.toggle('open');
              var isOpen = document.getElementById('navigation')
              if (isOpen.style.display === "block") {
                  isOpen.style.display = "none";
              } else {
                  isOpen.style.display = "block";
              }
          }
      },
      mounted: function(){
      }
      
    }
  </script>
  
  
  <style scoped>
  
  </style>