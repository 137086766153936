import Vuex from 'vuex';
import Vue from 'vue';
import createPersistedState from "vuex-persistedstate";
import boutiques from './modules/boutiques';

// Load Vuex
Vue.use(Vuex);
// Create store
export default new Vuex.Store({
  state: {
    lng : 'Français'
  },
  getters: {
    lng : (state) => state.lng
  },
  mutations: {
    setlng: function(state, {lang}){
        state.lng = lang;
    },
  },
  actions: {
    
  },
  modules: {
    boutiques
  },
  plugins: [createPersistedState()]
});
