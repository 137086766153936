<template>
  <div>
       <header id="topnav" class="defaultscroll sticky">
            <div class="container">
                <!-- Logo container-->
                <router-link :to="{path: `/${$i18n.locale}`}" class="logo">
                    <span class="logo-light-mode">
                        <img src="/lg.png" class="l-dark" style="height: 90px; width: 100px; padding: 10px;" alt="">
                        <img src="/lg.png" class="l-light" style="height: 90px; width: 100px; padding: 10px;" alt="">
                    </span>
                    <img src="/lg.png" style="height: 90px; width: 100px; padding: 10px;" class="logo-dark-mode" alt="">
                </router-link>
                <div class="buy-button">
                </div><!--end login button-->
                <!-- End Logo container-->
                <div class="menu-extras">
                    <div class="menu-item">
                        <!-- Mobile menu toggle-->
                        <a class="navbar-toggle" id="isToggle" @click="toggleMenu()">
                            <div class="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </a>
                        <!-- End mobile menu toggle-->
                    </div>
                </div>

                <div id="navigation">
                    <!-- Navigation Menu-->
                    <ul class="navigation-menu nav-light" style="padding-top: 10px;">
                        <li><router-link :to="{path: `/${$i18n.locale}`}" class="sub-menu-item">{{ $t('nav.acceuil') }}</router-link></li>
                        <li><router-link :to="{path: `/${$i18n.locale}/international`}" class="sub-menu-item">{{ $t('nav.inter') }}</router-link></li>
                        <li><a href="https://s2asport.com/fr" target="_blank" class="sub-menu-item" replace>{{ $t('nav.sport') }}</a></li>
                        <li><router-link :to="{path: `/${$i18n.locale}/services`}" class="sub-menu-item">{{ $t('nav.services') }}</router-link></li>
                        <!-- <li><router-link :to="{path: `/${$i18n.locale}/innovation`}" class="sub-menu-item">{{ $t('nav.inno') }}</router-link></li> -->
                        <li><router-link :to="{path: `/${$i18n.locale}/appartements`}" class="sub-menu-item">{{ $t('nav.appartement') }}</router-link></li>
                        <li class="has-submenu parent-menu-item">
                         <a>{{ lng }}</a><span class="menu-arrow"></span>
                            <ul class="submenu">
                                <li><a @click="changedtofr" class="sub-menu-item">{{ $t('lang1') }}</a></li>
                                <li><a @click="changedtoen" class="sub-menu-item">{{ $t('lang2') }}</a></li>
                            </ul>
                        </li>
                    </ul><!--end navigation menu-->
                </div><!--end navigation-->
            </div><!--end container-->
        </header><!--end header-->
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import de from '/public/js/app.js'
  import da from '/public/js/feather.min.js'
  import dd from '/public/js/bootstrap.bundle.min.js'
  import dc from '/public/js/plugins.init.js'
  import i18n from '../i18n'
  export default {
    name: 'Head',

    data: () => ({

    }),
    computed: {
     ...mapGetters(['lng']),   
    },
    methods: {
        changedtofr: function(){
            i18n.locale = 'fr'
            this.$store.commit('setlng',{lang: this.$t('lang1')})

            let language = this.$route.params.lang;
            if(language === 'en'){
                let splitted = this.$route.path.split(`${language}`);
                let url = '/fr'+splitted[1]
                this.$router.push(url)
            }
        },
        changedtoen: function(){
            i18n.locale = 'en'
            this.$store.commit('setlng',{lang: this.$t('lang2')})

            let language = this.$route.params.lang;
            if(language != 'en'){
                let splitted = this.$route.path.split(`${language}`);
                let url = '/en'+splitted[1]
                this.$router.push(url)
            }
        },
        toggleMenu: function(){
            document.getElementById('isToggle').classList.toggle('open');
            var isOpen = document.getElementById('navigation')
            if (isOpen.style.display === "block") {
                isOpen.style.display = "none";
            } else {
                isOpen.style.display = "block";
            }
        }
    },
  }
</script>