<template>
  <div>
        <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                        <a href="#" class="logo-footer">
                            <img src="/lg.png" style="height: 90px; width: 90px;" alt="">
                        </a>
                        <p class="mt-4">{{ $t('footer.logodesc') }}.</p>
                    </div><!--end col-->

                    <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0" style="width:450px">
                        <h5 class="text-light footer-head">{{ $t('footer.head1') }}</h5>
                        <p class="mt-4">{{ $t('foot') }}.</p>
                    </div><!--end col-->

                    <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-light footer-head">{{ $t('footer.head2') }}</h5>
                        <ul class="list-unstyled footer-list mt-4" style="padding-left:0 !important">
                            <li>(+228) 22 71 26 77</li>
                            <li>(+228) 91 75 29 82</li>
                            <li>(+228) 96 42 21 21</li>
                            <li>contact.s2atechnology@gmail.com</li>
                            </ul>
                    </div><!--end col-->

                    
                </div><!--end row-->
            </div><!--end container-->
        </footer><!--end footer-->
        <footer class="footer footer-bar">
            <div class="container text-center">
                <div class="row align-items-center">
                    <div class="col-sm-6">
                        <div class="text-sm-start">
                            <p class="mb-0">© 2019-{{ annee }} copyright S2A - {{ $t('allright') }}</p>
                        </div>
                    </div><!--end col-->

                </div><!--end row-->
            </div><!--end container-->
        </footer><!--end footer-->
  </div>
</template>

<script>
  import de from '/public/js/app.js'
  import da from '/public/js/feather.min.js'
  import dd from '/public/js/bootstrap.bundle.min.js'
  import dc from '/public/js/plugins.init.js'
  import i18n from '../i18n'
  export default {
    name: 'Foot',

    data: () => ({

    }),
    computed:{
    annee()
    {
        return (new Date().getFullYear())
    }   
  }
  }
</script>