import axios from 'axios';

const state = {
	albumsByUsers: [],
	oneAlbum: {},
	recent: [],
	others: [],
	services: [],
	cat: [],
	loading : true
};

const getters = {
	albumsByUsers: (state) => state.albumsByUsers,
	oneAlbum: (state) => state.oneAlbum,
	recent: (state) => state.recent,
	others: (state) => state.others,
	services: (state) => state.services,
	cat: (state) => state.cat,
};

const actions = {
	async getAlbumsByUsers({commit, rootState}) {
		let token = rootState.auth.token;
		let response = await axios.get('albums_user', {headers:{'Authorization': 'Bearer '+token}});
		let data = response.data;
		commit('setAlbumByUser', {albumsByUsers: data})
	},
	async getOneAlbum({commit, rootState},id) {
		let token = rootState.auth.token;
		let response = await axios.get('albums_optional/'+id, {headers:{'Authorization': 'Bearer '+token}});
		let data = response.data;
		commit('setOneAlbum', {oneAlbum: data})
	},
	async getRecent({commit}) {
		let response = await axios.get('auth/fichiers_s2a_cat');
		let data = response.data;
		commit('setRecent', {recent: data})
	},
	async getOthers({commit}) {
		let response = await axios.get('auth/fichiers_s2a_maillot');
		let data = response.data;
		commit('setOthers', {others: data})
	},
	async getCat({commit}) {
		let response = await axios.get('auth/fichiers_s2a_cat_all');
		let data = response.data;
		commit('setCat', {cat: data})
	},
	async getServices({commit}) {
		let response = await axios.get('auth/fichiers_s2a_2');
		let data = response.data;
		commit('setServices', {services: data})
	},
	async addFileToAlbum({dispatch, rootState}, dat) {
		let album = dat.album;
		let files = dat.files;
		let token = rootState.auth.token;
		var bodyFormData = new FormData();
		files.forEach(element => {
			bodyFormData.append('fichier', element,element.name);	
		});
		let response = await axios.post('fichiers_albums/'+album,bodyFormData,{
			headers:{
				'Authorization': 'Bearer '+token,
				'Content-Type': 'multipart/form-data'

		}});
		let data = response.data;
		dispatch('getOneAlbum', album)
	},
	async deleteOneAlbum({dispatch, rootState},id) {
		let token = rootState.auth.token;
		let response = await axios.delete('albums/'+id, {headers:{'Authorization': 'Bearer '+token}});
		let data = response.data;
		dispatch('getAlbumsByUsers')
	},
	async deleteOneFile({dispatch, rootState}, dat) {
		let file = dat.file;
		let id = dat.id;
		let token = rootState.auth.token;
		let response = await axios.delete('fichiers_albums/'+file.id, {headers:{'Authorization': 'Bearer '+token}});
		let data = response.data;
		dispatch('getOneAlbum', id)
	},
	  async postAlbum({dispatch,rootState},album) {
		let token = rootState.auth.token;
		var bodyFormData = new FormData();
		bodyFormData.append('boutique', album.boutique);
		bodyFormData.append('categorie', album.categorie);
		album.fichier.forEach(element => {
			bodyFormData.append('fichier', element,element.name);	
		});
		bodyFormData.append('titre', album.titre);
		bodyFormData.append('photo_entete', album.photo_entete);
		await axios.post('albums_file', 
			bodyFormData, {
			headers:{
				'Authorization': 'Bearer '+token,
				'Content-Type': 'multipart/form-data'
			}});
		dispatch("getAlbumsByUsers")
	  },
	  async updateAlbumFile({dispatch,rootState},id,file) {
		let token = rootState.auth.token;
		var bodyFormData = new FormData();
		bodyFormData.append('fichier', file,file.name);	
		await axios.post('albums_file/'+id, 
			bodyFormData, {
			headers:{
				'Authorization': 'Bearer '+token,
				'Content-Type': 'multipart/form-data'
			}});
		dispatch("getAlbumsByUsers")
	  },
};

const mutations = {
	loading(state){
		state.loading = true;
	},

	setAlbumByUser(state, {albumsByUsers}) {
		state.albumsByUsers = albumsByUsers;
		state.loading = false;
	},
	setOneAlbum(state, {oneAlbum}) {
		state.oneAlbum = oneAlbum;
	},
	setRecent(state, {recent}) {
		state.recent = recent;
	},
	setOthers(state, {others}) {
		state.others = others;
	},
	setCat(state, {cat}) {
		state.cat = cat;
	},
	setServices(state, {services}) {
		state.services = services;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};