<template>
<v-app>
  <div class="" id="">
        <!-- Navbar STart -->
        <Head></Head>
        <!-- Navbar End -->

        <!-- Hero Start -->
         <Banner></Banner>      
        <!-- Hero End -->

        <!-- Start Features -->
        <section class="py-4 bg-light">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="d-flex features pt-4 pb-4">
                            <div class="icon text-center rounded-circle text-primary me-3 h5 mb-0">
                                <i class="uil uil-analytics text-primary"></i>
                            </div>
                            <div class="flex-1">
                                <h4 class="title">{{ $t('home.innovation') }}</h4>
                                <p class="text-muted para mb-0">{{ $t('home.innodesc') }}.</p>
                            </div>
                        </div>
                    </div><!--end col-->

                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="d-flex features pt-4 pb-4">
                            <div class="icon text-center rounded-circle text-primary me-3 h5 mb-0">
                                <i class="uil uil-airplay text-primary"></i>
                            </div>
                            <div class="flex-1">
                                <h4 class="title">{{ $t('home.services') }}</h4>
                                <p class="text-muted para mb-0">{{ $t('home.servicesdesc') }}.</p>
                            </div>
                        </div>
                    </div><!--end col-->

                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="d-flex features pt-4 pb-4">
                            <div class="icon text-center rounded-circle text-primary me-3 h5 mb-0">
                                <i class="uil uil-clapper-board text-primary"></i>
                            </div>
                            <div class="flex-1">
                                <h4 class="title">{{ $t('home.international') }}</h4>
                                <p class="text-muted para mb-0">{{ $t('home.internationaldesc') }}.</p>
                            </div>
                        </div>
                    </div><!--end col-->

                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="d-flex features pt-4 pb-4">
                            <div class="icon text-center rounded-circle text-primary me-3 h5 mb-0">
                                <i class="uil uil-users-alt text-primary"></i>
                            </div>
                            <div class="flex-1">
                                <h4 class="title">{{ $t('home.sport') }}</h4>
                                <p class="text-muted para mb-0">{{ $t('home.sportdesc') }}.</p>
                            </div>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->
        </section><!--end section-->
        <!-- End Features -->

        <section class="section" id="services">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 text-center">
                        <div class="section-title mb-4 pb-2">
                            <h4 class="title mb-4">{{ $t('home.domaines') }}  ?</h4>
                            <p class="text-muted para-desc mb-0 mx-auto">{{ $t('home.domainesdesc') }}.</p>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->

                <div class="row">
                    <div class="col-lg-3 col-md-4 mt-4 pt-2">
                        <div class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                            <span class="h1 icon2 text-primary">
                                <i class="uil uil-chart-line"></i>
                            </span>
                            <div class="card-body p-0 content">
                                <h5>{{ $t('home.equipementier') }}</h5>
                                <p class="para text-muted mb-0"><br/>{{ $t('home.equipementierdesc') }}.</p>
                            </div>
                            <span class="big-icon text-center">
                                <i class="uil uil-chart-line"></i>
                            </span>
                        </div>
                    </div><!--end col-->

                    <div class="col-lg-3 col-md-4 mt-4 pt-2">
                        <div class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                            <span class="h1 icon2 text-primary">
                                <i class="uil uil-crosshairs"></i>
                            </span>
                            <div class="card-body p-0 content">
                                <h5>{{ $t('home.solutions') }}</h5>
                                <p class="para text-muted mb-0">{{ $t('home.solutionsdesc') }}.</p>
                            </div>
                            <span class="big-icon text-center">
                                <i class="uil uil-crosshairs"></i>
                            </span>
                        </div>
                    </div><!--end col-->

                    <div class="col-lg-3 col-md-4 mt-4 pt-2">
                        <div class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                            <span class="h1 icon2 text-primary">
                                <i class="uil uil-airplay"></i>
                            </span>
                            <div class="card-body p-0 content">
                                <h5>{{ $t('home.transfert') }}</h5>
                                <p class="para text-muted mb-0">{{ $t('home.transfertdesc') }}.</p>
                            </div>
                            <span class="big-icon text-center">
                                <i class="uil uil-airplay"></i>
                            </span>
                        </div>
                    </div><!--end col-->

                    <div class="col-lg-3 col-md-4 mt-4 pt-2">
                        <div class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                            <span class="h1 icon2 text-primary">
                                <i class="uil uil-rocket"></i>
                            </span>
                            <div class="card-body p-0 content">
                                <h5>{{ $t('home.innovation') }}</h5>
                                <p class="para text-muted mb-0"><br/>{{ $t('home.innovationdesc') }}.</p>
                            </div>
                            <span class="big-icon text-center">
                                <i class="uil uil-rocket"></i>
                            </span>
                        </div>
                    </div><!--end col-->

                    <div class="col-lg-3 col-md-4 mt-4 pt-2">
                        <div class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                            <span class="h1 icon2 text-primary">
                                <i class="uil uil-clock"></i>
                            </span>
                            <div class="card-body p-0 content">
                                <h5>{{ $t('home.support') }}</h5>
                                <p class="para text-muted mb-0"><br/>{{ $t('home.supportdesc') }}.</p>
                            </div>
                            <span class="big-icon text-center">
                                <i class="uil uil-clock"></i>
                            </span>
                        </div>
                    </div><!--end col-->

                    <div class="col-lg-3 col-md-4 mt-4 pt-2">
                        <div class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                            <span class="h1 icon2 text-primary">
                                <i class="uil uil-users-alt"></i>
                            </span>
                            <div class="card-body p-0 content">
                                <h5>{{ $t('home.shipping') }}</h5>
                                <p class="para text-muted mb-0"><br/>{{ $t('home.shippingdesc') }}.</p>
                            </div>
                            <span class="big-icon text-center">
                                <i class="uil uil-users-alt"></i>
                            </span>
                        </div>
                    </div><!--end col-->

                    <div class="col-lg-3 col-md-4 mt-4 pt-2">
                        <div class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                            <span class="h1 icon2 text-primary">
                                <i class="uil uil-file-alt"></i>
                            </span>
                            <div class="card-body p-0 content">
                                <h5>{{ $t('home.site') }}</h5>
                                <p class="para text-muted mb-0"><br/>{{ $t('home.sitedesc') }}.</p>
                            </div>
                            <span class="big-icon text-center">
                                <i class="uil uil-file-alt"></i>
                            </span>
                        </div>
                    </div><!--end col-->

                    <div class="col-lg-3 col-md-4 mt-4 pt-2">
                        <div class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                            <span class="h1 icon2 text-primary">
                                <i class="uil uil-search"></i>
                            </span>
                            <div class="card-body p-0 content">
                                <h5>{{ $t('home.dev') }}</h5>
                                <p class="para text-muted mb-0">{{ $t('home.devdesc') }}.</p>
                            </div>
                            <span class="big-icon text-center">
                                <i class="uil uil-search"></i>
                            </span>
                        </div>
                    </div><!--end col-->

                </div><!--end row-->
            </div><!--end container-->

            <!-- About Start -->
            <div class="container mt-100 mt-60">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6">
                        <div class="row align-items-center">
                           <img src="../../public/images/home/SEO_SVG.svg" alt="">
                        </div><!--end row-->
                    </div><!--end col-->

                    <div class="col-lg-6 col-md-6 mt-4 mt-lg-0 pt- pt-lg-0" id="propos">
                        <div class="ms-lg-4">
                            <div class="section-title mb-4 pb-2">
                                <h4 class="title mb-4">{{ $t('home.about') }}</h4>
                                <p class="text-muted para-desc mb-0"> {{ $t('home.aboutdesc') }}</p>
                            </div>

                            <ul class="list-unstyled text-muted">
                                <li class="mb-0"><span class="text-primary h4 me-2"><i class="uil uil-check-circle align-middle"></i></span>{{ $t('home.tech') }}</li>
                                <li class="mb-0"><span class="text-primary h4 me-2"><i class="uil uil-check-circle align-middle"></i></span>{{ $t('home.sport') }}</li>
                                <li class="mb-0"><span class="text-primary h4 me-2"><i class="uil uil-check-circle align-middle"></i></span>{{ $t('home.marchandising') }}</li>
                                <li class="mb-0"><span class="text-primary h4 me-2"><i class="uil uil-check-circle align-middle"></i></span>{{ $t('home.payement') }}</li>
                            </ul>

                        </div>
                    </div>
                </div><!--end row-->
            </div><!--end container-->
        </section><!--end section-->

        <!-- Start -->
        <section class="section pt-0">
            <div class="container">
                <div class="p-4 rounded shadow bg-primary bg-gradient position-relative" style="z-index: 1;">
                    <div class="row">
                        <div class="col-md-6 col-12">
                            <div class="progress-box">
                                <h6 class="title text-light title-dark">{{ $t('home.vente') }}</h6>
                                <div class="progress title-bg-dark" style="height: 10px; padding: 3px">
                                    <div class="progress-bar position-relative bg-black" style="width:90%;">
                                        <div class="progress-value d-block text-light title-dark h6">90%</div>
                                    </div>
                                </div>
                            </div><!--end process box-->
                            <div class="progress-box mt-4">
                                <h6 class="title text-light title-dark">{{ $t('home.marchandising') }}</h6>
                                <div class="progress title-bg-dark" style="height: 10px; padding: 3px">
                                    <div class="progress-bar position-relative bg-black" style="width:60%;">
                                        <div class="progress-value d-block text-light title-dark h6">60%</div>
                                    </div>
                                </div>
                            </div><!--end process box-->
                            <div class="progress-box mt-4">
                                <h6 class="title text-light title-dark">{{ $t('home.shipping') }}</h6>
                                <div class="progress title-bg-dark" style="height: 10px; padding: 3px">
                                    <div class="progress-bar position-relative bg-black" style="width:60%;">
                                        <div class="progress-value d-block text-light title-dark h6">60%</div>
                                    </div>
                                </div>
                            </div><!--end process box-->
                        </div><!--end col-->

                        <div class="col-md-6 col-12">
                            <div class="progress-box mt-4 mt-sm-0">
                                <h6 class="title text-light title-dark">{{ $t('home.devmobile') }}</h6>
                                <div class="progress title-bg-dark" style="height: 10px; padding: 3px">
                                    <div class="progress-bar position-relative bg-black" style="width:90%;">
                                        <div class="progress-value d-block text-light title-dark h6">90%</div>
                                    </div>
                                </div>
                            </div><!--end process box-->
                            <div class="progress-box mt-4">
                                <h6 class="title text-light title-dark">{{ $t('home.devweb') }}</h6>
                                <div class="progress title-bg-dark" style="height: 10px; padding: 3px">
                                    <div class="progress-bar position-relative bg-black" style="width:90%;">
                                        <div class="progress-value d-block text-light title-dark h6">90%</div>
                                    </div>
                                </div>
                            </div><!--end process box-->
                            <div class="progress-box mt-4">
                                <h6 class="title text-light title-dark">{{ $t('home.creationweb') }}</h6>
                                <div class="progress title-bg-dark" style="height: 10px; padding: 3px">
                                    <div class="progress-bar position-relative bg-black" style="width:90%;">
                                        <div class="progress-value d-block text-light title-dark h6">90%</div>
                                    </div>
                                </div>
                            </div><!--end process box-->
                        </div><!--end col-->
                    </div><!--end row -->
                </div>

                <div class="row mt-2 pt-1 position-relative" id="counter" style="z-index: 1;">

                </div><!--end row-->
                <div class="feature-posts-placeholder bg-light"></div>
            </div><!--end container-->
        </section><!--end section-->
        <!-- End -->

        <section class="section" style="padding-top: 50px;">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12">
                        <div class="section-title text-center mb-4 pb-2">
                            <h6 class="text-primary">{{ $t('home.methode') }}</h6>
                            <h4 class="title mb-4">{{ $t('home.comment') }} ?</h4>
                            <p class="text-muted para-desc mx-auto mb-0">{{ $t('home.start') }} <span class="text-primary fw-bold">{{ $t('home.s2a') }}</span> {{ $t('home.start1') }}.</p>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->

                <div class="row">
                    <div class="col-md-4 mt-4 pt-2">
                        <div class="card features feature-clean work-process bg-transparent process-arrow border-0 text-center">
                            <div class="icons text-primary text-center mx-auto">
                                <i class="uil uil-presentation-edit d-block rounded h3 mb-0"></i>
                            </div>

                            <div class="card-body">
                                <h5 class="text-dark">{{ $t('home.met1') }}</h5>
                                <p class="text-muted mb-0">{{ $t('home.metdesc1') }}</p>
                            </div>
                        </div>
                    </div><!--end col-->

                    <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
                        <div class="card features feature-clean work-process bg-transparent process-arrow border-0 text-center">
                            <div class="icons text-primary text-center mx-auto">
                                <i class="uil uil-airplay d-block rounded h3 mb-0"></i>
                            </div>

                            <div class="card-body">
                                <h5 class="text-dark">{{ $t('home.met2') }}</h5>
                                <p class="text-muted mb-0">{{ $t('home.metdesc2') }}.</p>
                            </div>
                        </div>
                    </div><!--end col-->

                    <div class="col-md-4 mt-md-5 pt-md-5 mt-4 pt-2">
                        <div class="card features feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
                            <div class="icons text-primary text-center mx-auto">
                                <i class="uil uil-image-check d-block rounded h3 mb-0"></i>
                            </div>

                            <div class="card-body">
                                <h5 class="text-dark">{{ $t('home.met3') }}</h5>
                                <p class="text-muted mb-0">{{ $t('home.metdesc3') }}.</p>
                            </div>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->
        </section>

            <section class="section bg-light">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="section-title text-center text-lg-start">
                                <h6 class="text-primary"></h6>
                                <h4 class="title mb-4 mb-lg-0">{{ $t('home.project') }}</h4>
                            </div>
                        </div><!--end col-->
            
                        <div class="col-lg-6">
                            <div class="section-title text-center text-lg-start">
                                <p class="text-muted mb-0 mx-auto para-desc">{{ $t('home.trust') }} <span class="text-primary fw-bold">{{ $t('home.s2a') }}</span> {{ $t('home.trust1') }}.</p>
                            </div>
                        </div>
                    </div>
                </div>
            
                <div class="container">
                    <div class="row justify-content-center">
                       
                        <div @click="shopmanager" class="col-lg-2 col-md-2 col-6 text-center pt-5">
                            <img style="height:50px" src="images/home/logo2.png" class="avatar" alt="">
                        </div><!--end col-->

                         <div @click="oanke" class="col-lg-2 col-md-2 col-6 text-center pt-5">
                            <img style="height:50px" src="images/home/logo1.png" class="avatar" alt="">
                        </div><!--end col-->

                         <div @click="s2apay" class="col-lg-2 col-md-2 col-6 text-center pt-5">
                            <img style="height:50px" src="images/home/logo3.png" class="avatar" alt="">
                        </div><!--end col-->
                         <div @click="s2aexpress" class="col-lg-2 col-md-2 col-6 text-center pt-5">
                            <img style="height:50px" src="images/home/logo4.png" class="avatar" alt="">
                        </div><!--end col-->
            
                    </div><!--end row-->
                </div><!--end container-->
        </section><!--end section-->

        <!-- Footer Start -->
        <Foot></Foot>
        <!-- Footer End -->
  </div>
</v-app>
</template>

<script>
  import {mapGetters} from 'vuex'
  import de from '/public/js/app.js'
  import da from '/public/js/feather.min.js'
  import dd from '/public/js/bootstrap.bundle.min.js'
  import dc from '/public/js/plugins.init.js'
  import i18n from '../i18n'
  import Foot from '../components/Foot.vue'
  import Head from '../components/Head.vue'
  import Banner from '../components/Banner.vue'
  export default {
    name: 'Home',
    components: {
        Foot,
        Head,
        Banner
    },
    data: () =>{
            return {
            }
        },
    computed: {
     ...mapGetters(['lng']),   
    },
    methods: {
        oanke: function(){
            window.open('https://oanke.com/fr')
        },
        shopmanager: function(){
            window.open('https://shopymanager.com/fr')
        },
        s2apay: function(){
            window.open('https://s2apay.com')
        },
        s2aexpress: function(){
            window.open('https://s2aservice.com')
        },
        changedtofr: function(){
            i18n.locale = 'fr'
            this.$store.commit('setlng',{lang: this.$t('lang1')})

            let language = this.$route.params.lang;
            if(language === 'en'){
                let splitted = this.$route.path.split(`${language}`);
                let url = '/fr'+splitted[1]
                this.$router.push(url)
            }
        },
        changedtoen: function(){
            i18n.locale = 'en'
            this.$store.commit('setlng',{lang: this.$t('lang2')})

            let language = this.$route.params.lang;
            if(language != 'en'){
                let splitted = this.$route.path.split(`${language}`);
                let url = '/en'+splitted[1]
                this.$router.push(url)
            }
        },
        toggleMenu: function(){
            document.getElementById('isToggle').classList.toggle('open');
            var isOpen = document.getElementById('navigation')
            if (isOpen.style.display === "block") {
                isOpen.style.display = "none";
            } else {
                isOpen.style.display = "block";
            }
        }
    },
    mounted: function(){
     this.$store.dispatch('getRecent');
     this.$store.dispatch('getOthers');
     this.$store.dispatch('getCat');
    }
    
  }
</script>


<style scoped>

</style>

