<template>
<v-app>
  <div class="" id="">
        <!-- Navbar STart -->
        <Head></Head>
        <!-- Navbar End -->

                <!-- Hero Start -->
                <section class="home-slider position-relative">
                    <div id="carouselExampleInterval" class="carousel slide carousel-fade" data-bs-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active" data-bs-interval="3000">
                                <div class="bg-home slider-rtl-2 d-flex align-items-center" style="background:url('../../images/sport/banner-4.jpg') center center;background-size: cover">
                                    <div class="container">
                                        <div class="row align-items-center mt-5">
                                            <div class="col-lg-7 col-md-7">
                                                <div class="title-heading mt-4">
                                                    <h1 class="myh1 title-white fw-bold mb-3" style="color:#fffef4">{{ $t('sport.coll') }} <br> {{ $t('sport.ass') }}</h1>
                                                    </div>
                                            </div><!--end col-->
                                        </div><!--end row-->
                                    </div><!--end container-->
                                </div>
                            </div>

                            <div class="carousel-item" data-bs-interval="3000">
                                <div class="bg-home slider-rtl-1 d-flex align-items-center" style="background:url('../../images/sport/banner-2.jpg') center center;background-size: cover">
                                    <div class="container">
                                        <div class="row align-items-center mt-5">
                                            <div class="col-lg-7 col-md-7">
                                                <div class="title-heading mt-4">
                                                    <h1 class="myh1 title-white fw-bold mb-3" style="color:#fffef4">{{ $t('sport.head3') }}</h1>
                                                    </div>
                                            </div><!--end col-->
                                        </div><!--end row-->
                                    </div><!--end container-->
                                </div>
                            </div>

                        </div>
                        <a class="carousel-control-prev bg-dark" href="#carouselExampleInterval" role="button" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">{{ $t('prev') }}</span>
                        </a>
                        <a class="carousel-control-next bg-dark" href="#carouselExampleInterval" role="button" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">{{ $t('next') }}</span>
                        </a>
                    </div>
                </section><!--end section-->
                <!-- Hero End -->

        <!-- Features Start -->
        <div class="container-fluid mt-4">
            <div class="row justify-content-center">
                <div class="col-12 text-center">
                    <div class="section-title mb-4 pb-2">
                        <p  style="margin-top: 50px; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif ;font-size: 45px;">{{ $t('sport.head') }}</p>
                         </div>
                </div><!--end col-->
            </div><!--end row-->
            <div class="row">
                <div class="col-md-4 mt-4 pt-2">
                    <div class="card shop-features border-0 rounded overflow-hidden">
                        <img src="/images/sport/cat-3.jpg" class="img-fluid" alt="">
                        <div class="category-title">
                            <h4 style="color:#fffef4">{{ $t('sport.head1') }}</h4>
                            <a href="" class="btn btn-sm btn-soft-primary mt-2">{{ $t('sport.high') }}</a>
                        </div>
                    </div>
                </div><!--end col-->

                <div class="col-md-4 mt-4 pt-2">
                    <div class="card shop-features border-0 rounded overflow-hidden">
                        <img src="/images/sport/cat-1.jpg" class="img-fluid" alt="">
                        <div class="category-title">
                            <h4 style="color:#fffef4">{{ $t('sport.head2') }}</h4>
                            <a href="" class="btn btn-sm btn-soft-primary mt-2">{{ $t('sport.high') }}</a>
                        </div>
                    </div>
                </div><!--end col-->

                <div class="col-md-4 mt-4 pt-2">
                    <div class="card shop-features border-0 rounded overflow-hidden">
                        <img src="/images/sport/cat-4.jpg" class="img-fluid" alt="">
                        <div class="category-title">
                            <h4 style="color:#fffef4">{{ $t('sport.head3') }}</h4>
                            <a href="" class="btn btn-sm btn-soft-primary mt-2">{{ $t('sport.high') }}</a>
                        </div>
                    </div>
                </div><!--end col-->
            </div><!--end row-->
        </div><!--end container-->
        <!-- Features End -->

        <section class="section">
            <div class="container mt-50 mt-60">
                <div class="row align-items-end mb-4 pb-2">
                    <div class="col-md-8">
                        <div class="section-title text-center text-md-start">
                            <h4 class="title mb-4">{{ $t('sport.chaussures') }}</h4>
                            <p class="text-muted mb-0 para-desc">{{ $t('sport.find') }} <span class="text-primary fw-bold">{{ $t('home.s2a') }}</span>.</p>

                           </div>
                    </div><!--end col-->

                    <div class="col-md-4 mt-4 mt-sm-0">
                        <div class="text-center text-md-end">
                            <a @click="visit" class="text-primary h6">{{ $t('sport.more') }} <i data-feather="arrow-right" class="fea icon-sm"></i></a>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->

<v-sheet
    class="mx-auto"
  >
    <v-slide-group
      v-model="model"
      class="pa-4"
      center-active
      show-arrows
    >
      <v-slide-item
        v-for="n in cat"
        v-bind:key="n.id"
      >
        <v-card
          @click="setImage(n)"
          data-bs-toggle="modal"
          data-bs-target="#productview"
          elevation:2
          class="ma-4"
          height="400"
          width="300"
        >
           <v-img
              :src="n.name"
              class="white--text align-end"
              height="400"
            >
            </v-img>
        </v-card>
      </v-slide-item>
    </v-slide-group>
  </v-sheet>


        </section><!--end section-->

        <section class="section" style="padding-top: 0;">
            <div class="container mt-10">
                <div class="row align-items-end mb-4 pb-2">
                    <div class="col-md-8">
                        <div class="section-title text-center text-md-start">
                            <h4 class="title mb-4">{{ $t('sport.maillots') }}</h4>
                            <p class="text-muted mb-0 para-desc">{{ $t('sport.chez') }} <span class="text-primary fw-bold">{{ $t('home.s2a') }},</span> {{ $t('sport.chez1') }}.</p>
                        </div>
                    </div><!--end col-->

                    <div class="col-md-4 mt-0 mt-sm-0">
                        <div class="text-center text-md-end">
                            <a @click="visit" class="text-primary h6">{{ $t('sport.visit') }} <i data-feather="arrow-right" class="fea icon-sm"></i></a>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->
            <v-sheet
    class="mx-auto"
  >
    <v-slide-group
      v-model="model1"
      class="pa-4"
      center-active
      show-arrows
    >
      <v-slide-item
        v-for="p in others"
        v-bind:key="p.id"
      >
        <v-card
          @click="setImage(p)"
          data-bs-toggle="modal"
          data-bs-target="#productview"
          elevation:2
          class="ma-4"
          height="400"
          width="300"
        >
           <v-img
              :src="p.name"
              class="white--text align-end"
              height="400"
            >
            </v-img>
        </v-card>
      </v-slide-item>
    </v-slide-group>
  </v-sheet>
        </section><!--end section-->

        <section class="section" style="padding-top: 0;">
            <div class="container mt-10">
                <div class="row align-items-end mb-4 pb-2">
                    <div class="col-md-8">
                        <div class="section-title text-center text-md-start">
                            <h4 class="title mb-4">{{ $t('sport.art') }}</h4>
                            <p class="text-muted mb-0 para-desc">{{ $t('sport.chez') }} <span class="text-primary fw-bold">{{ $t('home.s2a') }},</span> {{ $t('sport.chez1') }}.</p>
                        </div>
                    </div><!--end col-->

                    <div class="col-md-4 mt-0 mt-sm-0">
                        <div class="text-center text-md-end">
                            <a @click="visit" class="text-primary h6">{{ $t('sport.visit') }} <i data-feather="arrow-right" class="fea icon-sm"></i></a>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->
            <v-sheet
    class="mx-auto"
  >
    <v-slide-group
      v-model="model1"
      class="pa-4"
      center-active
      show-arrows
    >
      <v-slide-item
        v-for="p in recent"
        v-bind:key="p.id"
      >
        <v-card
          @click="setImage(p)"
          data-bs-toggle="modal"
          data-bs-target="#productview"
          elevation:2
          class="ma-4"
          height="400"
          width="300"
        >
           <v-img
              :src="p.name"
              class="white--text align-end"
              height="400"
            >
            </v-img>
        </v-card>
      </v-slide-item>
    </v-slide-group>
  </v-sheet>
        </section><!--end section-->

        <Foot></Foot>
        <!-- Footer End -->
  </div>
          <!-- Product View Start -->
        <div class="modal fade" id="productview" tabindex="-1" aria-labelledby="productview-title" aria-hidden="true">
            <div class="modal-dialog  modal-lg modal-dialog-centered">
                <div class="modal-content rounded shadow border-0" style="background-color: transparent;!important">
                    <div class="modal-header border-bottom transparent">
                        <button type="button" class="btn-close white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    
                    <div class="modal-body p-4">
                        <div class="container-fluid px-0">
                                <div class="col-lg-5 center">
                                    <div class="tiny-slide"><img :src="image.name" class="img-fluid rounded" alt=""></div>
                                </div><!--end col-->
                            
                        </div><!--end container-->
                    </div>
                </div>
            </div>
        </div>
        <!-- Product View End -->

</v-app>
</template>

<script>
  import {mapGetters} from 'vuex'
  import dp from '../../public/js/tobii.min.js'
  import dr from '../../public/js/tiny-slider.js'
  import de from '/public/js/app.js'
  import da from '/public/js/feather.min.js'
  import dd from '/public/js/bootstrap.bundle.min.js'
  import dc from '../../public/js/plugins.init.js'
  import i18n from '../i18n'
  import Foot from '../components/Foot.vue'
  import Head from '../components/Head.vue'
  export default {
    name: 'Sport',
    components: {
        Foot,
        Head
    },
    data: () =>{
            return {
                dialog: false,
                image: {},
                model: null,
                model1: null,
                tabImages:[
                    {url:'/images/sport/slider-1.jpg',text:'Paris'},
                    {url:'/images/sport/slider-1.jpg',text:'Paris'},
                    {url:'/images/sport/slider-2.jpg',text:'Paris'},
                    {url:'/images/sport/slider-1.jpg',text:'Paris'}
                ],
            }
        },
    computed: {
     ...mapGetters(['lng']),
     ...mapGetters(['recent']),
     ...mapGetters(['cat']),
     ...mapGetters(['others']),   
    },
    methods: {
        setImage(image){

                this.image = image;
            },
        visit: function(){
            window.open('https://oanke.com/fr/shopHome/1')
        },
        changedtofr: function(){
            i18n.locale = 'fr'
            this.$store.commit('setlng',{lang: this.$t('lang1')})

            let language = this.$route.params.lang;
            if(language === 'en'){
                let splitted = this.$route.path.split(`${language}`);
                let url = '/fr'+splitted[1]
                this.$router.push(url)
            }
        },
        changedtoen: function(){
            i18n.locale = 'en'
            this.$store.commit('setlng',{lang: this.$t('lang2')})

            let language = this.$route.params.lang;
            if(language != 'en'){
                let splitted = this.$route.path.split(`${language}`);
                let url = '/en'+splitted[1]
                this.$router.push(url)
            }
        },
        toggleMenu: function(){
            document.getElementById('isToggle').classList.toggle('open');
            var isOpen = document.getElementById('navigation')
            if (isOpen.style.display === "block") {
                isOpen.style.display = "none";
            } else {
                isOpen.style.display = "block";
            }
        }
    },
    mounted: function(){
     this.$store.dispatch('getRecent');
     this.$store.dispatch('getOthers');
     this.$store.dispatch('getCat'); 
    }
    
  }
</script>


<style scoped>

.center {
margin: auto;
width: 60%;
padding: 10px;
}

@media (min-width: 501px) {
    .myh1 {
    font-size: 6rem !important;
font-weight: 300;
line-height: 6rem;
letter-spacing: -0.015625em !important;
font-family: "Roboto", sans-serif !important;
}
}

@media (max-width: 500px) {
    .myh1 {
        font-size: 2rem !important;
    }
}
</style>