<template>
<v-app>
  <div class="" id="">
        <!-- Navbar STart -->
            <Head></Head>
        <!-- Navbar End -->

        <!-- Hero Start -->
            <Banner></Banner>
        <!-- Hero End -->

        <!-- Section Start -->
       
        <!--end section-->
        <!-- section End -->

                <!-- Start -->
             
                <!--end section-->
                <!-- End -->

                <!-- Start Features -->
                <div class="container mt-100">
                <div class="">
                    <div class="col-12 text-left">
                        <div class="section-title mb-1 pb-2">
                            <h4 class="title mb-4">{{ $t('appartement.titre1') }}  </h4>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->

                <div class="row">
                    <div class="col-lg-4 col-md-4 mt-4 pt-2">
                        <div class="card rounded bg-light text-left position-relative border-0">
                            <img src="../../public/images/appartement/17.jpg" height="300px" alt="" />
                            <div class="card-body pt-10 content">
                                <h5>{{ $t('appartement.card1.titre') }}</h5>
                                <p class="para text-muted mb-0"><br/>{{ $t('appartement.card1.libelle') }}.</p>
                            </div>
                            <div class="text-center mb-4">
                                <a
                                    href="https://oanke.com/fr/shopHome/S2A%20APPARTS"
                                    target="_blank"
                                >
                                    <v-btn outlined small color="#049372">{{
                                    $t("appartement.plus")
                                    }}</v-btn>
                                </a>
                            </div>
                        </div>
                    </div><!--end col-->

                    <div class="col-lg-4 col-md-4 mt-4 pt-2">
                        <div class="card rounded bg-light text-left position-relative border-0">
                            <img src="../../public/images/appartement/3.jpg" height="300px" alt="" />
                            <div class="card-body pt-10 content">
                                <h5>{{ $t('appartement.card2.titre') }}</h5>
                                <p class="para text-muted mb-0"><br/>{{ $t('appartement.card2.libelle') }}.</p>
                            </div>
                            <div class="text-center mb-4">
                                <a
                                    href="https://oanke.com/fr/shopHome/S2A%20APPARTS"
                                    target="_blank"
                                >
                                    <v-btn outlined small color="#049372">{{
                                    $t("appartement.plus")
                                    }}</v-btn>
                                </a>
                            </div>
                        </div>
                    </div><!--end col-->

                    <div class="col-lg-4 col-md-4 mt-4 pt-2">
                        <div class="card rounded bg-light text-left position-relativ border-0">
                            <img src="../../public/images/appartement/7.jpg" height="300px" ealt="" />
                            <div class="card-body pt-10 content">
                                <h5>{{ $t('appartement.card3.titre') }}</h5>
                                <p class="para text-muted mb-0"><br/>{{ $t('appartement.card3.libelle') }}.</p>
                            </div>
                            <div class="text-center mb-4">
                                <a
                                    href="https://oanke.com/fr/shopHome/S2A%20APPARTS"
                                    target="_blank"
                                >
                                    <v-btn outlined small color="#049372">{{
                                    $t("appartement.plus")
                                    }}</v-btn>
                                </a>
                            </div>
                        </div>
                    </div><!--end col-->

                </div><!--end row-->
            </div>
        <section class="section container">
            <div class="">
                    <div class="col-12 text-left">
                        <div class="section-title mb-1 pb-2">
                            <h4 class="title mb-4">{{ $t('appartement.images') }} </h4>
                        </div>
                    </div><!--end col-->
                </div>

        <v-carousel
          cycle
          hide-delimiters
          show-arrows-on-hover
          :height="carouselHeight"
          :padding="padding"
        >
          <v-carousel-item
            v-for="(item, index) in items2"
            :key="index"
            :height="itemHeight"
            @click="showPreview2(index)"
          >
            <v-img :src="item.src" style="width:1200px;height:500px;" />
          </v-carousel-item>
        </v-carousel>
        <div class="text-center mt-4">
          <a
            href="https://oanke.com/fr/shopHome/S2A%20APPARTS"
            target="_blank"
          >
            <v-btn outlined large color="#049372">{{
              $t("appartement.plus1")
            }}</v-btn>
          </a>
        </div>
      </section>
                <!--end container-->

        <!-- Footer Start -->
            <Foot></Foot>
        <!-- Footer End -->
  </div>
</v-app>
</template>

<script>
  import {mapGetters} from 'vuex'
  import de from '/public/js/app.js'
  import da from '/public/js/feather.min.js'
  import dd from '/public/js/bootstrap.bundle.min.js'
  import dc from '/public/js/plugins.init.js'
  import i18n from '../i18n'
  import Foot from '../components/Foot.vue'
  import Head from '../components/Head.vue'
  import Banner from '../components/Banner.vue'
  export default {
    name: 'Innovation',
    components: {
        Foot,
        Head,
        Banner
    },
    data (){
            return {
                model: null,
                model1: null,
                tabImages:[
                    {url:'/images/appartement/1.jpg',text:'Paris'},
                    {url:'/images/appartement/2.jpg',text:'Paris'},
                    {url:'/images/appartement/3.jpg',text:'Paris'},
                    {url:'/images/appartement/4.jpg',text:'Paris'},
                    {url:'/images/appartement/5.jpg',text:'Paris'},
                    {url:'/images/appartement/6.jpg',text:'Paris'},
                    {url:'/images/appartement/7.jpg',text:'Paris'},
                    {url:'/images/appartement/8.jpg',text:'Paris'},
                    {url:'/images/appartement/9.jpg',text:'Paris'},
                ],
                customers:[
                    {name:" Pour mes transactions à l'étranger, je me fie à S2A. C'est rapide, simple et efficace. ",text:'- Denkey Aser'},
                    {name:" Les services offerts par S2A International ont été à la hauteur de mes espérances surtout que leurs couts sont abordables. ",text:'- Tossou Daniel'},
                    {name:" J'ai entendu parlé de S2A par le biais d'un ami. Et depuis je ne fais confiance qu'à eux. ",text:'Dossou Franjuste'},
                    {name:" Le dévelopement d'application est un vaste monde. Pour mieux cerner la réalisation de mon projet, j'ai pu compter sur S2A. ",text:'- Onewin Femi'},
                    {name:" Ce que je préfère avec S2A c'est leur fiabilité et la qualité de leur service client.",text:'- Taboukouna Florent'},
                    {name:" Désormais je ne fais confiance qu'à S2A pour m'équiper en articles de sport de qualité. ",text:'- Gbeze Martin'}
                ],
                items2: [
                    { src: "/images/appartement/1.jpg" },
                    { src: "/images/appartement/2.jpg" },
                    { src: "/images/appartement/3.jpg" },
                    { src: "/images/appartement/4.jpg" },
                    { src: "/images/appartement/5.jpg" },
                    { src: "/images/appartement/6.jpg" },
                    { src: "/images/appartement/7.jpg" },
                    { src: "/images/appartement/8.jpg" },
                    { src: "/images/appartement/9.jpg" },
                    { src: "/images/appartement/10.jpg" },
                ],
                // carouselHeight: "500px",
                padding: 0,
                showPreviewDialog: false,
                showPreviewDialog2: false,
                currentImageIndex: 0,
                currentImageIndex2: 0,
            }
        },
    computed: {
        ...mapGetters(['lng']),

        carouselWidth() {
        return (this.carouselWidth / 5) * 2;
        },
        carouselHeight() {
        return this.$vuetify.breakpoint.width / 4;
        },
        itemWidth() {
        return (this.carouselWidth / 5) * 0.8;
        },
        itemHeight() {
        return this.carouselHeight;
        },

    },
    methods: {
        changedtofr: function(){
            i18n.locale = 'fr'
            this.$store.commit('setlng',{lang: this.$t('lang1')})

            let language = this.$route.params.lang;
            if(language === 'en'){
                let splitted = this.$route.path.split(`${language}`);
                let url = '/fr'+splitted[1]
                this.$router.push(url)
            }
        },
        changedtoen: function(){
            i18n.locale = 'en'
            this.$store.commit('setlng',{lang: this.$t('lang2')})

            let language = this.$route.params.lang;
            if(language != 'en'){
                let splitted = this.$route.path.split(`${language}`);
                let url = '/en'+splitted[1]
                this.$router.push(url)
            }
        },
        toggleMenu: function(){
            document.getElementById('isToggle').classList.toggle('open');
            var isOpen = document.getElementById('navigation')
            if (isOpen.style.display === "block") {
                isOpen.style.display = "none";
            } else {
                isOpen.style.display = "block";
            }
        }
    },
    mounted: function(){
    }
    
  }
</script>


<style scoped>

</style>